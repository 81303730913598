export const en = {
  no: "No.",
  No: "No",
  Yes: "Yes",
  lock: "Lock",
  lockNo: "No",
  open: "Open",
  close: "Close",
  to: "To",
  edit: "Edit",
  confirm: "confirm",
  cancel: "Cancel",
  submit: "Submit",
  update: "Update",
  reset: "Reset",
  active: "Active",
  deactive: "Deactive",
  delete: "Delete",
  message: "Message",
  noRecordFound: "No record found",
  loading: "Loading...",
  copy: "Copy",
  all: "All",
  team: "Team",
  credit: "Credit",
  status: "Status",
  detail: "Detail",
  information: "Information",
  odds: "Odds",
  stake: "Stake",
  winlose: "Winlose",
  running: "Running",
  total: "Total",
  setting: "Setting",
  mark: "Mark",
  cancelMark: "Cancel Mark",
  win: "Win",
  lose: "Lose",
  draw: "Draw",
  allActive: "All Active",
  payout: "Payout",
  payoutTotal: "Payout Total",
  type: "Type",
  betting: "Betting",
  language: {
    title: "Language",
    english: "English",
    thai: "Thai",
  },
  time: {
    title: "Time",
    fullTime: "Full Time",
    firstTime: "First Time",
    firstHalf: "First Half",
    halfTime: "Half Time",
  },
  day: {
    date: "Date",
    selectDate: "Select Date",
    toDate: "To Date",
    today: "Today",
    yesterday: "Yesterday",
    currentWeek: "Current Week",
    lastWeek: "Last Week",
    currentMonth: "Current Month",
    lastMonth: "Last Month",
  },
  user: {
    title: "User",
    username: "Username",
    fullname: "Fullname",
    loginName: "Login Name",
    password: "Password",
    phone: "Phone",
    lastLogin: "Last Login",
    signIn: "SIGN IN",
    LogOut: "Log Out",
  },
  level: {
    title: "Level",
    under: "Under",
    member: "Member",
    agent: "Agent",
    master: "Master",
    senior: "Senior",
    super: "Super",
    company: "Company",
    0: "",
    1: "Member",
    2: "Agent",
    3: "Master",
    4: "Senior",
    5: "Super",
    6: "Company",
    memberTotal: "Member Total",
    agentTotal: "Agent Total",
    masterTotal: "Master Total",
    seniorTotal: "Senior Total",
    superTotal: "Super Total",
    companyTotal: "Company Total",
  },
  totalBet: {
    title: "Total Bet",
    simple: "Simple",
    ballStep: {
      title: "Ball Step",
      ballStep: "Ball Step",
    },
    singleBet: "Single Bet",
    memberOutstanding: "Member Outstanding",
    sideMenu: {
      simpleHdpOu: "Simple HDP/OU",
      simpleHtHdpOu: "Simple HT HDP/OU",
      simple1X2Oe: "Simple 1X2/OE",
    },
  },
  memberMgmt: {
    title: "Member Mgmt",
    newAccount: {
      titleContent: "Create new account",
      title: "New Account",
      userInformation: "User Information",
      positionTaking: "Position Taking",
      comm: "COMM",
      comms: "comm",
      couple: "",
      currency: "Currency",
      positionSetting: "Position Setting",
      available: "Available",
      unavailable: "Unavailable",
      status: "Status",
      remaining: "Remaining",
      forceShare: "Force Share",
      accountId: "Account ID",
      passwordIsRequired: "Password is required",
    },
    accountList: {
      title: "Account List",
      changePassword: "Change Password",
      inCaseChangePasswordOnly: "In case change password only",
    },
    betSetting: {
      title: "Bet Setting",
      setting: "Setting",
      update: "Update",
    },
    suspend: "Suspend",
    no: "No",
    odds: "Odds",
    betting: {
      title: "Betting",
      bettingSetting: "Betting Settings",
    },
    commission: {
      title: "Commission",
      commission: "Commission",
    },
    oddsType: "Odds Type",
    minBet: "Min Bet",
    maxBet: "Max Bet",
    maxPerMatch: "Max Per Match",
    maxPayout: "Max Payout",
    stepMin: "Step Min",
    stepMax: "Step Max",
    single: "Single",
    fh: "FH",
    ht: "HT",
    ticket: "Ticket",
    ticketStatus: "Ticket Status",
    slip: "Slip",
    printSlip: "Print Slip",
    auto: "Auto",
    remaining: "Remaining",
    forceShare: "Force Share",
    credit: {
      title: "Credit",
      maxCredit: "Max Credit",
      creditCurrent: "Credit Current",
      topUp: "Topup",
      withdraw: "Withdraw",
      record: "Record",
      topUpBy: "Topup By",
      topUpTo: "Topup To",
      creditAmount: "Credit Amount",
      beforeAmount: "Before Amount",
      afterAmount: "After Amount",
      activeBy: "Active By",
      topUpTime: "Topup Time",
    },
    presetPT: {
      title: "Preset PT",
      ptSetting: "PT Setting",
    },
    effectivePT: {
      title: "Effective PT",
      status: "Status",
    },
  },
  report: {
    title: "Report",
    winloseSimple: "Winlose Simple",
    statement: "Statement",
    matchResult: "Match Result",
    cancelBetList: "Cancel Bet List",
    result: {
      title: "Result",
      firstHalfScore: "First Half Score",
      fullTimeScore: "Full Time Score",
      allTitle: "Daily",
      pendingTitle: "Pending",
      id: "ID",
      time: "KO",
      match: "Match",
      halftime: "Halftime",
      fulltime: "Fulltime",
      edit: "Edit Result",
      status: "Status",
      all: "All",
      pending: "Pending",
      incoming: "Incoming",
      fh: "FH",
      ht: "HT",
      play1h: "Play 1 Hour",
      play2h: "Play 2 Hour",
      complete: "Complete",
      refundFT: "Refund FT",
      refund1H: "Refund 1H",
      updateMatchResultSuccess: "Update success",
    },
  },
  account: {
    title: "Account",
    announcement: "Announcement",
    myProfile: "My Profile",
    balanceInformation: {
      title: "Balance Information",
      outstanding: "{level} Outstanding",
      totalOutstanding: "Total Outstanding",
      creditSuper: "Credit Super",
      creditSenior: "Credit Senior",
      creditMaster: "Credit Master",
      creditAgent: "Credit Agent",
      creditMember: "Credit Member",
      superPlayableLimit: "Super Playable Limit",
      seniorPlayableLimit: "Senior Playable Limit",
      masterPlayableLimit: "Master Playable Limit",
      agentPlayableLimit: "Agent Playable Limit",
      memberPlayableLimit: "Member Playable Limit",
      superAmount: "Senior Amount (active/deactive)",
      seniorAmount: "Senior Amount (active/deactive)",
      masterAmount: "Master Amount (active/deactive)",
      agentAmount: "Agent Amount (active/deactive)",
      memberAmount: "Member Amount (active/deactive)",
    },
    changePassword: {
      title: "Change Password",
      currentPassword: "Current Password",
      newPassword: "New Password",
      confirmPassword: "Confirm Password",
    },
  },
  alias: {
    title: "Alias",
    aliasManagement: "Alias Management",
  },
  printSlip: {
    title: "Print Slip",
    howToSetup: "How To Setup",
    howToUsePrintSlipSystem: "How to use print slip system.",
    updatePrintSlip: "Update Print Slip",
    updatePrintSlipApplication: "Update print slip application.",
    downloadPrintSlip: "Download Print Slip v3.0.1",
  },
  errorMessage: {
    currentPasswordErrorReq: "Current Password cannot be blank.",
    currentPasswordErrorMin:
      "Current Password should contain at least 6 characters.",
    currentPasswordErrorNotCorrect: "Current Password is not correct",
    changePasswordFailed: "Change Password Failed",
    newPasswordErrorReq: "New Password cannot be blank.",
    newPasswordErrorMin: "Password should contain at least 6 characters.",
    confirmPasswordErrorReq: "Confirm Password cannot be blank.",
    confirmPasswordErrorMin: "Password should contain at least 6 characters.",
    confirmPasswordErrorreFine:
      "Confirm Password must be equal to 'New Password'.",
  },
  successMessage: {
    changePasswordSuccess: "Change Password Success",
  },
  ticketStatus: {
    cancel: "Cancel",
    waiting: "Waiting",
    running: "Running",
    complete: "Complete",
  },
};
